.nav {
  height: 50px;
  margin: 0px auto;
}
.nav ul {
  list-style-type: none;
  height: 100%;
  padding: 0px;
  margin: 0px auto;
}

.nav li {
  height: 100%;
  float: left;
  padding: 0px 15px;
}

.nav a {
  color: black;
  display: block;
  line-height: 50px;
  height: 100%;
  text-decoration: none;
  text-align: center;
}
