.carouselOuterWrapper {
  min-width: 300px;
  height: 340px;
  margin: 20px auto 25px;
  justify-content: center;
}

.carouselInnerWrapper {
  position: inherit;
  min-width: 300px;
  height: 180px;
  display: flex;
  margin: 20px auto 25px;
  justify-content: center;
}

.carouselInnerWrapperClientSays {
  position: inherit;
  min-width: 300px;
  height: 180px;
  display: flex;
  margin: 20px auto 25px;
}

.carouselItems {
  width: auto;
  height: 342px;
  margin-left: 10px;
  margin-right: 10px;
}

.carouselItems:first-child {
  margin-left: 0px;
}

.carouselItemsContainer {
  overflow: hidden;
  height: 400px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.carouselClientsItemsContainer {
  overflow: hidden;
  height: 223px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.carouselItemsClientsSay {
  display: flex;
  width: auto;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.prevButton {
  display: inline-block;
  height: 302px;
  width: 20px;
  line-height: 302px;
  cursor: pointer;
}

.nextButton {
  display: inline-block;
  height: 302px;
  width: 16px;
  line-height: 302px;
  cursor: pointer;
}

.prevButtonClientsSays {
  display: inline-block;
  height: 180px;
  width: 20px;
  line-height: 180px;
  cursor: pointer;
}

.nextButtonClientsSays {
  display: inline-block;
  height: 180px;
  width: 16px;
  line-height: 180px;
  cursor: pointer;
}
