.product {
  position: relative;
  width: 221px;
  height: 342px;
  border: none;
  display: inline-block;
  margin: 0px 10px;
}

.product img {
  width: 100%;
  height: 302px;
}

.productDesc {
  position: absolute;
  padding: 5px 0px;
  font-family: showcardgothic;
  font-size: 14px;
  bottom: 0px;
  background-color: rgb(0, 0, 0);
  color: white;
  text-align: center;
  width: 100%;
  white-space: normal;
  min-height: 40px;
  text-transform: uppercase;
}
