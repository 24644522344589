.productsWrapper{
  position: inherit;
  width: 100%;
  display: flex;
  margin: 20px auto 25px;
}

.products {
  overflow: hidden;
  width: calc(100% - 66px);
  height: 342px;
  margin-left: 10px;
  margin-right: 10px;
}

.products:first-child{
  margin-left: 0px;
}

.productsContainer {
  height: 800px;
  width: 100%;
}

.prevButton {
    display: inline-block;
    height: 302px;
    width: 10px;
    line-height: 302px;
    cursor: pointer;
}

.nextButton {
    display: inline-block;
    height: 302px;
    width: 16px;
    line-height: 302px;
}
