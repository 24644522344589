.ourProducts .product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  clear: both;
  padding-top: 10px;
}

.ourProducts .product:nth-of-type(even) .text {
  margin-right: 15px;
  width: calc(50% - 15px);
}

.ourProducts .product:nth-of-type(even) .image {
  width: 50%;
}

.ourProducts .product:nth-of-type(odd) .text {
  margin-left: 15px;
  width: calc(50% - 15px);
  order: 2;
}

.ourProducts .product:nth-of-type(odd) .image {
  width: 50%;
}

.image img {
  width: 100%;
  margin-bottom: 5px;
}

@media screen and (max-width: 968px){
  .ourProducts .product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    clear: both;
    padding-top: 10px;
    flex-direction: column;
  }

  .ourProducts .text {
    order: 1;
    margin: 0px 10px !important;
    width: calc(100% - 20px) !important;
  }

  .ourProducts .image {
    order: 0;
    margin: 0px 10px !important;
    width: calc(100% - 20px) !important;
  }
}