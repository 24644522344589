.slide {
  display: none;
}

.slideActive {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: darkgoldenrod;
  position: absolute;
  justify-content: center;
}

.slideActive img {
  height: 100%;
  width: 100%;
}

.fade {
  animation-name: fade;
  animation-duration: 0.8s;
}

@media screen and (max-width: 1338px){
  .slideActive img {
  left: 0%;
}
}
@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
