.Suppliers {
  display: flex;
  height: auto;
  margin: 25px auto;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (min-width: 601px) and (max-width: 1800px) {
  .Suppliers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    margin: 30px 0px 0px 0px;
    padding: 10px;
    min-height: 200px;
  }
}

@media screen and (max-width: 600px) {
  .Suppliers {
    display: grid;
    grid-template-columns: repeat(2, 30%);
    justify-items: center;
    align-items: center;
    margin: 30px 0px 0px 0px;
    padding: 10px;
    min-height: 200px;
  }
}
