.Presentation p {
    columns: 60px 2;
    margin-bottom: 15px;
}

@media screen and (max-width: 968px){
    .Presentation p {
        margin-bottom: 15px;
        columns: initial;
    }
}