.footer {
  display: grid;
  grid-template-rows: 110px 50px 40px;
  row-gap: 20px;
  background-color: #c5b4e3;
  width: 100%;
  height: 305px;
  text-align: center;
}

.footer .loggoContainer {
  display: inline-block;
  margin-top: 8px;
  height: 50px;
}

.footer .loggo {
  height: 100px;
}

.footer .small {
  line-height: 40px;
  font-family: Avenir;
}

.footer .socialIcons {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  width: 100px;
}

.footer .socialIcons a{
  color: white;
}
