.layout {
  position: relative;
  min-height: 100vh;
  height: 100vh;
}
.mainContent {
  width: 60%;
  margin: 0px auto;
  min-height: 100vh;
  padding-bottom: 95px;
}

@media screen and (max-width: 968px) {
  .mainContent {
    width: calc(100% - 20px);
  }
}
