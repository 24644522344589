.MenuButton {
  display: none;
}

@media screen and (max-width: 968px) {
  .MenuButton {
    display: block;
    margin-left: 5px;
  }
}
