body{
  margin: 0px;
  padding: 0px;
  user-select: none;
}

#modalContainer{
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: showcardgothic;
  src: url("fonts/SHOWG.TTF");
}

@font-face {
  font-family: Avenir;
  src: url("fonts/Avenir Next LT Pro Regular.otf");
}
p{
  white-space: pre-wrap;
}
p, a, label, ul, ol{
  font-family: Avenir;
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6, button{
  font-family: showcardgothic;
}

h1, h2, h3, h4, h5, h6{
  text-align: center;
  margin: 0px 0px 10px 0px;
}

h1{
  font-size: 28px;
}

h2{
  font-size: 14px;
}

a{
  cursor: pointer;
}