.Instagram {
  background-color: #fff;
  padding-block: 20px;
  margin-bottom: 15px;
}

:is(.Instagram) > h1,
p {
  margin: 0px;
}

:is(.Instagram) > h1 {
  text-align: center;
  margin: 0px;
}

:is(.Instagram) > p:not(.LoadingMessage) {
  text-align: left;
  margin: 0px;
}

.Instagram a.AccountName {
  display: block;
  font-family: Avenir;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  color: black;
}

.Instagram .ButtonContainer {
  display: flex;
  justify-content: center;
}

.ImageContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 33%);
  row-gap: 10px;
  column-gap: 10px;
  margin: 10px 0px;
}

.ImageContainer .LoadingMessage{
  text-align: center;
  margin: 20px auto;
  display: block;
  grid-column-start: 2;
}
