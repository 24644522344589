.Supplier {
  padding: 5px 0px;
}

.Supplier img {
  height: 30px;
}

.SpecialHeightHeltLogiskt img {
  height: 64px !important;
}

.SpecialHeightSmaHjartan img {
  height: 50px !important;
}

.SpecialHeightKronan img {
  height: 20px !important;
}

.PlaceInMiddleColumn {
  grid-column-start: 2;
}

@media screen and (max-width: 600px) {
  .Supplier img {
    width: 100%;
    height: auto;
  }

  .SpecialHeightHeltLogiskt img {
    height: auto !important;
  }

  .SpecialHeightKronan img {
    height: auto !important;
  }

  .PlaceInMiddleColumn {
    grid-column-start: 1;
  }
}