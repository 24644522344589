.Modal{
    position: fixed;
    top:10%;
    left: 25%;
    width: 50%;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #000;
    border-radius: 10px;
    z-index: 101;
    max-height: 80%;
    overflow-y: hidden;
}

.CloseButton{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.Modal .Body{
    min-height: 100px;
    width: 100%;
    border-radius: 10px;
}

.Open{
    display: block;
}

.Close{
    display: none;
}

@media screen and (max-width: 968px) {
    .Modal{
        width: 90%;
        left: 5%;
    }
}