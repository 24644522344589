.CompanyLogo{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
    margin: 5px 0px 10px 0px;
}

.CompanyLogo img{
    max-width: 100%;
    height: 120px;
    margin: 5px 0px;
}