.Backdrop {
  position: fixed;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(204, 204, 204, 0.8);
  z-index: 100;
}

.Open {
  display: block;
}

.Close {
  display: none;
}
