.Button, .InstaButton {
  padding: 5px 10px;
  cursor: pointer;
  border: none;

}

.Button {
  background-color: black;
  color: white;
  font-size: 14px;
}

.Button:disabled {
  background-color: black;
  opacity: 0.1;
  cursor: auto;
}

.InstaButton {
  position: relative;
  left: 50%;
  background-color: #c5b4e3;
}
