.nav {
  background-color: #c5b4e3;
  height: 60px;
  min-width: 568px;
}
.nav ul {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  height: 100%;
  padding: 0px;
  margin: 0px auto;
  width: 90%;
}

.nav li {
  user-select: none;
  height: 100%;
  float: left;
  flex: 1;
}

.nav li:last-child {
  flex: 2;
}

.nav a {
  color: black;
  display: block;
  line-height: 60px;
  height: 100%;
  text-decoration: none;
  text-align: center;
}

.nav .active {
  text-decoration: underline;
}

@media screen and (max-width: 968px) {
  .nav {
    position: absolute;
    background-color: #c5b4e3;
    height: 100%;
    min-width: 100%;
    transition: transform 0.2s ease-out;
    z-index: 500;
  }

  .nav ul {
    margin: 0px;
  }

  .nav.open {
    transform: translate3d(0px, 0px, 0px);
  }

  .nav.close {
    transform: translate3d(-100%, 0px, 0px);
  }

  .nav ul {
    display: block;
    list-style-type: none;
    height: 100%;
    padding: 0px;
    margin: 0px auto;
    width: 90%;
  }

  .nav li {
    display: block;
    height: 40px;
    width: 100%;
    padding: 0px 5px;
  }

  .nav a {
    color: black;
    display: block;
    line-height: 40px;
    height: 100%;
    text-decoration: none;
    text-align: center;
  }
}
