.InstagramImage img{
    height: auto;
    min-height: 100%;
    width: 100%;
}
.InstagramImage{
    transition: opacity 700ms;
    cursor: pointer;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.InstagramImage:hover{
    opacity: 0.8;
}