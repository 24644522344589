.pageTemplate {
  display: flex;
  padding-top: 25px;
}

.pageTemplateOurProducts{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 568px){
  .pageTemplate {
    flex-direction: column;
  }
}
