.InstagramModalTemplate {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 50% calc(50% - 10px);
}

.InstagramModalTemplate .InstagramPicture img {
  height: 100%;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
}

.InstagramModalTemplate .Caption {
  font-family: Avenir;
  padding: 34px 4px 4px 4px;
  word-break: break-word;
}

@media screen and (max-width: 968px) {
  .InstagramModalTemplate {
    display: flex;
    flex-direction: column;
    grid-template-columns: 80% calc(20% - 10px);
  }

  .InstagramModalTemplate .InstagramPicture img {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }

  .InstagramModalTemplate .Caption {
    font-family: Avenir;
    padding: 4px 0px 4px 4px;
    word-break: break-word;
    overflow: scroll;
  }
}
