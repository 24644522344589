.slideShowContainer {
  display: flex;
  position: relative;
  margin: auto;
  aspect-ratio: 318 / 109;
}

.buttonContainer {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  width: 100%;
  z-index: 99;
  margin-bottom: 20px;
}

@media screen and (min-width: 968px) and (max-width: 1338px) {
  .slideShowContainer {
    width: 100%;
    position: relative;
    margin: 0px;
    height: 400px;
  }
}

@media screen and (max-width: 968px) {
  .slideShowContainer {
    aspect-ratio: 2195 / 1417;
  }
}
