.text {
  margin-right: 5px;
  width: calc(50% - 20px);
}

.image {
  margin-left: 15px;
  padding-bottom: 10px;
  width: calc(50% - 15px);
}
.noImgText {
  width: calc(50% - 20px);
  margin: 0px auto;
}

.noImg{
  display: none;
}

.image img{
  width: 100%;
}

@media screen and (max-width: 968px){
  .text {
    order:1;
    margin: 0px 10px 10px;
    width: calc(100% - 20px);
    float: none;
  }

  .image {
    width: calc(100% - 20px);
    margin: 0px 10px;
  }
  .noImgText {
    width: calc(100% - 20px);
    margin: 0px auto;
  }
}
